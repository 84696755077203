import {
  ContentfulContentType,
  PerkDocument,
  PerkOfTheWeekDocument,
} from "@tiicker/util/lib/contentful/types";
import { EntryCollection } from "contentful";
import { useContentfulEntries } from "source/contentful/provider";
import FeaturedPerkTile from "./FeaturedPerkTile";
import styles from "./HomeFeaturedPerks.module.scss";
import Container from "components/core/Layout/Container/Container";
import SwiperCarousel from "components/Carousel/SwiperCarousel";

type Props = {
  perksOfTheWeek: EntryCollection<PerkOfTheWeekDocument> | undefined;
};

const HomeFeaturedPerks = (props: Props) => {
  const perkIds = props.perksOfTheWeek
    ? props.perksOfTheWeek.items.map((x) => x.fields.perk.sys.id)
    : [];

  const perks = useContentfulEntries<PerkDocument>(ContentfulContentType.Perk, {
    query: {
      content_type: ContentfulContentType.Perk,
      "sys.id[in]": perkIds.join(","),
    },
  });

  if (perks.state !== "DONE" || !props.perksOfTheWeek) {
    return <div></div>;
  }

  return (
    <div className={styles.HomeFeaturedPerks__container}>
      <Container>
        <h3 className={styles.title}>Featured Perks</h3>
      </Container>
      <SwiperCarousel
        pagination
        navigation
        // Note: Changing `spaceBetween` to 16 causes weird offset
        // issues, setting to zero maintains some distance between
        // elements and offsets correctly
        spaceBetween={0}
        slidesPerGroup={1}
        slideClass={styles.slide}
        openSide="right"
        breakPoints={{
          1000: {
            slidesPerGroup: 1,
          },
          768: {
            slidesPerGroup: 1,
          },
          100: {
            slidesPerGroup: 1,
          },
        }}
        slides={props.perksOfTheWeek.items.slice(0, 10).map((x, i) => {
          const perk = perks.result.items.find(
            (p) => p.sys.id === x.fields.perk.sys.id
          );

          if (!perk && !x.fields.perk) {
            return <></>;
          }

          return (
            <FeaturedPerkTile
              key={`featured-perk-${x.sys.id}`}
              perk={perk ?? x.fields.perk}
              perkOfTheWeek={i === 0}
              includes={perks.result.includes}
            />
          );
        })}
      />
    </div>
  );
};

export default HomeFeaturedPerks;
