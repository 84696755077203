import React, { useState } from "react";
import { useUser } from "components/UserContext/UserContext";
import Button from "components/core/Button/Button";
import ConnectBrokerageModal from "components/PlaidLink/ConnectBrokerageModal/ConnectBrokerageModal";
import classNames from "classnames";
import styles from "./PerkAlert.module.scss";
import { useAuth } from "source/hooks";
import { triggerCTAClick } from "source/analytics-events";
import Markdown from "components/Markdown/Markdown";

interface Props {
  showButton?: boolean;
  flexLarge?: boolean;
  location?: "Home" | "Onboarding" | "Dashboard";
  title?: string;
  description?: string;
  largeMargin?: boolean;
}

const PerkAlert = (props: Props) => {
  const userContext = useUser();
  const auth = useAuth();
  const [openBrokerageConnect, setOpenBrokerageConnect] =
    useState<boolean>(false);

  return (
    <>
      <div
        className={classNames(
          styles.container,
          props.showButton && styles.containerClickable,
          props.largeMargin && styles.largeMargin
        )}
        onClick={() => {
          if (props.location) {
            triggerCTAClick(props.location);
          }

          if (props.showButton && userContext.state.userId) {
            setOpenBrokerageConnect(true);
          } else if (props.showButton) {
            auth.smsSignUp();
          }
        }}
      >
        <div className={styles.containerBlur} />
        <div
          className={classNames(
            styles.contentContainer,
            props.flexLarge && styles.contentContainer__large
          )}
        >
          <img
            src={"/images/claimed-award.svg"}
            className={styles.claimedAwardImage}
          />
          <div>
            <div className={styles.titleContainer}>
              <h4 className={styles.title}>
                {props.title ? (
                  <Markdown markdownText={props.title} />
                ) : (
                  "Perk alert!"
                )}
              </h4>
              <img
                src={"/images/tiicker-perk-badge.png"}
                className={styles.tiickerPerkBadgeImage}
              />
            </div>
            <p className={styles.description}>
              {props.description ? (
                <Markdown markdownText={props.description} />
              ) : (
                <>
                  You instantly qualify for an{" "}
                  <b>$11 Amazon or Visa gift card</b> after connecting your
                  first brokerage to TiiCKER with a portfolio value of $100 or
                  more.
                </>
              )}
            </p>
            {props.showButton && (
              <Button className={styles.connectButton} type="inline" arrow>
                {userContext.state.userId ? "Connect a brokerage" : "Join Now"}
              </Button>
            )}
          </div>
        </div>
      </div>
      <ConnectBrokerageModal
        visible={openBrokerageConnect}
        onClose={() => setOpenBrokerageConnect(false)}
        openPlaid={() => userContext.openPlaid()}
      />
    </>
  );
};

export default PerkAlert;
